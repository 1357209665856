/* Bootstrap is still installed, but I have grown aversion to it for some reason,
if I find a way to work with MUI Grid, BS can be yeeted */

import React from 'react';
import Login from "./Login";
import Dashboard from "./Dashboard";
import Cookies from 'universal-cookie';
import {setSessionKey} from "./redux/Sessions/sessions.actions";

import {
	BrowserRouter as Router,
	Route,
	Switch,
} from 'react-router-dom';

import {connect} from 'react-redux';

class App extends React.Component {
	constructor(props) {
		super(props);
		
		const cookies = new Cookies();
		let session_key = cookies.get('session');
		if (!(typeof(session_key) === "undefined")) {
			this.props.setSessionKey(session_key);
		}
	}
	
	render() {
		if (!this.props.session_key) {
			return (
				<div className="App" style={{height: '100%'}}>
					<Router>
						<Switch>
							<Route>
								<Login/>
							</Route>
						</Switch>
					</Router>
				</div>
			);
		} else {
			return (
				<div className="App" style={{height: '100%'}}>
					<Router>
						<Switch>
							<Route path="/login">
								<Login/>
							</Route>
							<Route>
								<Dashboard/>
							</Route>
						</Switch>
					</Router>
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		session_key: state.session_state.session_key
	}
}

function mapDispatchToProps(dispatch){
	return {
		setSessionKey: (session_key) => dispatch(setSessionKey(session_key))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
