import React from "react";
import axios from 'axios';
import {
	Button,
	Typography,
	Card,
	CardContent
} from "@material-ui/core";

import Config from "../config";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles'
import LineGraph from "./LineGraph";

import {updateVolumeData, clearVolumeData, updateMassData, clearMassData, updateGroupData, clearGroupData} from "../redux/ShipData/shipdata.actions";

const moment = require('moment');

const drawerWidth = 240;
const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  middleContainer: {
	 height: `calc(100% - ${theme.spacing(3)}px)`,
	 display: 'flex',
	 alignItems: 'center',
	 justifyContent: 'center',
  },
  vesselMessage: {
	  color: '#707070'
  },
  lineCard: {
	  marginTop: "10px"
  }
});

class ShipTotalsGraph extends React.Component {
	constructor(props) {
		super(props);

		this.RenderGraph = this.RenderGraph.bind(this);
		this.RenderGroupMassGraphs = this.RenderGroupMassGraphs.bind(this);
		this.RenderGroupMassGraph = this.RenderGroupMassGraph.bind(this);
		this.getLastVolumeString = this.getLastVolumeString.bind(this);
		this.getLastMassString = this.getLastMassString.bind(this);
	}
	
	RenderGraph() {
		const { classes } = this.props;
		
		if (this.props.current_setting === "mass") {
			return (
				<React.Fragment>
					<Typography variant='h6' className={classes.vesselMessage}>
						Total Actual Mass {this.getLastMassString()}
					</Typography>
					<LineGraph label="Actual Mass" yLabel="Mass (kg)" backgroundColor="rgba(255, 121, 77, 0.5)" borderColor="rgb(255, 121, 77)" data={this.props.mass_data} />
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					<Typography variant='h6' className={classes.vesselMessage}>
						Total Actual Volume {this.getLastVolumeString()}
					</Typography>
					<LineGraph label="Actual Volume" yLabel="Volume (Liter)" backgroundColor="rgba(77, 121, 255, 0.5)" borderColor="rgb(77, 121, 255)" data={this.props.volume_data} />
				</React.Fragment>
			);
		}
	}

	
	RenderGroupMassGraphs() {
		const { classes } = this.props;
		
		if (this.props.current_setting === "mass") {
			return (
				Object.keys(this.props.group_data).map((key, index) => (
					<Card key={"tankdetail-" + key} className={classes.lineCard}>
						<CardContent>
							{this.RenderGroupMassGraph(key)}
						</CardContent>
					</Card>
				))
			);
		} else {
			return (<React.Fragment></React.Fragment>);
		}
	}
	
	RenderGroupMassGraph(key) {
		const { classes } = this.props;
		
		return (
				<React.Fragment>
					<Typography variant='h6' className={classes.vesselMessage}>
						{key} Tanks - Actual Mass {this.getLastGroupMassString(key)}
					</Typography>
					<LineGraph label={key + " - Actual Mass"} yLabel="Mass (kg)" backgroundColor="rgba(255, 121, 77, 0.5)" borderColor="rgb(255, 121, 77)" data={this.props.group_data[key].mass} />
				</React.Fragment>
			)
	}
	
	getLastVolumeString() {
		let lastEntry = this.props.volume_data.slice(-1)[0];
		
		if (lastEntry) {
			return "(" + lastEntry.y.toLocaleString('nl') +" liter @ " + lastEntry.x.fromNow() + ")";
		} else {
			return "";
		}
	}
	
	getLastMassString() {
		let lastEntry = this.props.mass_data.slice(-1)[0];
		
		if (lastEntry) {
			return "(" + lastEntry.y.toLocaleString('nl') +" kg @ " + lastEntry.x.fromNow() + ")";
		} else {
			return "";
		}
	}
	
	getLastGroupMassString(key) {
		let lastEntry = this.props.group_data[key].mass.slice(-1)[0];
		
		if (lastEntry) {
			return "(" + lastEntry.y.toLocaleString('nl') +" kg (D=" + lastEntry.d + ") @ " + lastEntry.x.fromNow() + ")";
		} else {
			return "";
		}
	}
	
    render() {
		const { classes } = this.props;
		
        return (
			<React.Fragment>
				<Card className={classes.lineCard}>
					<CardContent>
					{this.RenderGraph()}
					</CardContent>
				</Card>
				{this.RenderGroupMassGraphs()}
			</React.Fragment>
		);
    };

	
    updateShipData(ship_id, start, end){
        const ship_url = Config.API_URL + "ship_data_total.php?id=" + ship_id + "&start=" + moment(start).format("YYYY-MM-DD") + "&end=" + moment(end).format("YYYY-MM-DD");
		
        axios.get(ship_url, { headers: {"X-Session-Token": this.props.session_key} }).then((resp) => {
            this.prepareGraphData(resp.data);
        }).catch((error) => {
			this.props.clearVolumeData();
			this.props.clearMassData();
			this.props.clearGroupData();
            console.log(error);
        })
    }

	
	prepareGraphData(data) {
		if (!data) {
			this.props.clearVolumeData()
			this.props.clearMassData();
			this.props.clearGroupData();
			return;
		}
		
		if (data.status !== "success") {
			this.props.clearVolumeData();
			this.props.clearMassData();
			this.props.clearGroupData();
			return;
		}
		
		let raw_data = data.data;
		let groups = data.groups;
		let volume_graph_data = [];
		let mass_graph_data = [];
		var offset = new Date().getTimezoneOffset();
        for (const [, entry] of raw_data.entries()) {
            let entry_date = moment(entry.datetime).add(-offset, 'minutes');
			
            volume_graph_data.push(
                {
                    x: entry_date,
                    y: Math.round(entry.volume)
                }
            )
			
			if (entry.density != null) {
				mass_graph_data.push(
					{
						x: entry_date,
						y: Math.round(entry.volume * entry.density)
					}
				)
			}
        }
		
		let groups_graph_data = {};
		
		for (const [, group] of groups.entries()) {
			let group_data = {
				id: group.id,
				name: group.name,
			}
			
			let group_graph_data = []
			for (const [, entry] of group.data.entries()) {
				let entry_date = moment(entry.datetime).add(-offset, 'minutes');
				
				if (entry.density != null) {
					group_graph_data.push({
						x: entry_date,
						y: Math.round(entry.volume * entry.density),
						d: parseFloat(entry.density).toFixed(4)
					});
				}
			}
			
			group_data.mass = group_graph_data;
			groups_graph_data[group_data.name] = group_data;
		}
		
		console.log(groups_graph_data);
		
		this.props.updateVolumeData(volume_graph_data);
		this.props.updateMassData(mass_graph_data);
		this.props.updateGroupData(groups_graph_data);
	}

	componentDidUpdate(prevProps) {
		let changed = false;
		if (prevProps.current_ship.ship_id !== this.props.current_ship.ship_id) {
			changed = true;
		}

		if (prevProps.start_date !== this.props.start_date) {
			changed = true;
		}

		if (prevProps.end_date !== this.props.end_date) {
			changed = true;
		}

		if (changed) {
			this.props.clearVolumeData();
			this.props.clearMassData();
			this.props.clearGroupData();
			this.updateShipData(this.props.current_ship.ship_id, this.props.start_date, this.props.end_date);
		}
	}


	componentDidMount() {
    	this.props.clearVolumeData();
		this.props.clearMassData();
		this.props.clearGroupData();
		this.updateShipData(this.props.current_ship.ship_id, this.props.start_date, this.props.end_date);
	}
}

ShipTotalsGraph.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		session_key: state.session_state.session_key,
		current_ship: state.vesselpicker_state.current_ship,
		start_date: state.vesselpicker_state.start_date,
		end_date: state.vesselpicker_state.end_date,
		volume_data: state.shipdata_state.volume_data,
		mass_data: state.shipdata_state.mass_data,
		group_data: state.shipdata_state.group_data,
		current_setting: state.settings_state.current_setting
	}
}

function mapDispatchToProps(dispatch) {
	return {
		updateVolumeData: (volume_data) => dispatch(updateVolumeData(volume_data)),
		updateMassData: (mass_data) => dispatch(updateMassData(mass_data)),
		updateGroupData: (group_data) => dispatch(updateGroupData(group_data)),
		clearVolumeData: () => dispatch(clearVolumeData()),
		clearMassData: () => dispatch(clearMassData()),
		clearGroupData: () => dispatch(clearGroupData())
	}
}

export default  connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles) (ShipTotalsGraph));