import {LOGINNAME_CHANGED, PASSWORD_CHANGED, LOGINFAILDIALOG_SHOWN, LOADINGOVERLAY_SHOWN} from "./login.types";

export const setLoginName = (login_name) => {
    return {
        type: LOGINNAME_CHANGED,
        payload: login_name
    }
}

export const setPassword = (password) => {
    return {
        type: PASSWORD_CHANGED,
        payload: password
    }
}

export const openFailDialog = (open) => {
    return {
        type: LOGINFAILDIALOG_SHOWN,
        payload: open
    }
}

export const showLoadingOverlay = (shown) => {
    return {
        type: LOADINGOVERLAY_SHOWN,
        payload: shown
    }
}