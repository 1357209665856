import React from "react";
import {Line} from "react-chartjs-2";
import * as zoom from 'chartjs-plugin-zoom';

import {
	Button
} from "@material-ui/core";

class LineGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			label: props.label,
			backgroundColor: props.backgroundColor,
			borderColor: props.borderColor,
			data: props.data,
			yLabel: props.yLabel,
        }
		
		this.graphRef = null;
		this.graphContainerRef = null;
		
		this.resetZoom = this.resetZoom.bind(this);
		this.render = this.render.bind(this);
		this.updateDimensions = this.updateDimensions.bind(this);
    }
	
	GraphData() {
		let that = this;
		let data = {
			datasets: [{
				label: that.state.label,
				backgroundColor: that.state.backgroundColor,
				borderColor: that.state.borderColor,
				data: that.state.data,
				type: 'line',
				pointRadius: 0,
				fill: true,
				lineTension: 0,
				borderWidth: 1
			}]
		};
		
		return data;
	}
	
	GraphOptions() {
		let that = this; 
		let options = {
			responsive: true,
			maintainAspectRatio: false,
			zoom: {
				enabled: true,
				drag:true,
				mode:'xy'
			},
			animation: {
				duration: 0
			},
			onResize: function(canvas, size) {
				//console.log(size);
			},
			tooltips: {
					intersect: false,
					mode: 'index',
					callbacks: {
						label: function(tooltipItem, myData) {
							var label = myData.datasets[tooltipItem.datasetIndex].label || '';
							if (label) {
								label += ': ';
							}
							label += tooltipItem.value;
							return label;
						}
					}
			},
			scales: {
				xAxes: [{
					type: 'time',
					distribution: 'linear',
					offset: true,
					time: {
						displayFormats: {
							minute: 'D MMM YYYY H:mm',
							hour: 'D MMM YYYY H:mm',
							day: 'D MMM YYYY H:mm',
							month: 'MMM YYYY'
						}
					},
					ticks: {
						major: {
							enabled: true,
							fontStyle: 'bold'
						},
						source: 'data',
						autoSkip: true,
						autoSkipPadding: 75,
						maxRotation: 0,
						sampleSize: 100
					}
				}],
				yAxes: [{
					gridLines: {
						drawBorder: false
					},
					scaleLabel: {
						display: true,
						labelString: that.state.yLabel
					},
					ticks: {
						beginAtZero: true
					}
				}]
			}
		};
		
		return options;
	}
	
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data ||
			prevProps.backgroundColor !== this.props.backgroundColor ||
			prevProps.borderColor !== this.props.borderColor ||
			prevProps.label !== this.props.label ||
			prevProps.yLabel !== this.props.yLabel) {
				this.setState({
					label: this.props.label,
					backgroundColor: this.props.backgroundColor,
					borderColor: this.props.borderColor,
					data: this.props.data,
					yLabel: this.props.yLabel,
				});
				this.resetZoom();
		}
    }


    componentDidMount() {
		window.addEventListener("resize", this.updateDimensions);
    }
	
	updateDimensions() {
		if (this.graphContainerRef) {
			
			let ref = this.graphContainerRef;
			let graph = this.graphRef.chartInstance;
			ref.style.maxWidth = "1px";
			
			window.setTimeout(function() {
				console.log(ref);
				ref.style.maxWidth = "none";
				if (graph) {
					graph.resize();
				}
			}, 1);
			
		}
	}
	
	resetZoom() {
		if (this.graphRef) {
			this.graphRef.chartInstance.resetZoom();
		}
	}

    render() {
        return(
			<React.Fragment>
				<div ref={(reference) => (this.graphContainerRef=reference)}>
					<Line height={300} data={this.GraphData()} options={this.GraphOptions()} ref={(reference) => (this.graphRef=reference)} />
				</div>
				<Button onClick={this.resetZoom}>
					Reset Zoom
				</Button>
			</React.Fragment>
        )
    }
}

export default LineGraph;