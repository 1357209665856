import {SESSIONKEY_SET, SESSIONKEY_CLEARED} from "./sessions.types";

const INITIAL_STATE = {
    session_key: null
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SESSIONKEY_CLEARED:
            return {
                ...state,
                session_key: null
            }
        case SESSIONKEY_SET:
            return {
                ...state,
                session_key: action.payload
            }
        default:
            return state
    }
}

export default reducer;
