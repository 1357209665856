import React from "react";
import {
    Typography,
} from "@material-ui/core";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'


const drawerWidth = 240;
const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  middleContainer: {
	 height: `calc(100% - ${theme.spacing(3)}px)`,
	 display: 'flex',
	 alignItems: 'center',
	 justifyContent: 'center',
  },
  vesselMessage: {
	  color: '#707070'
  }
});

class ChooseShip extends React.Component {
    render() {
		const { classes } = this.props;
        return (
			<div className={classes.middleContainer}>
				<div>
					<Typography variant='h5' className={classes.vesselMessage}>
						Please choose a vessel from the menu
					</Typography>
				</div>
			</div>
		);
    };
}

ChooseShip.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default  withStyles(useStyles) (ChooseShip);