import {
    VOLUME_DATA_CLEARED,
    TANK_DATA_CLEARED,
    TANK_DATA_UPDATED,
    VOLUME_DATA_UPDATED,
    MASS_DATA_UPDATED, MASS_DATA_CLEARED,
	GROUP_DATA_UPDATED, GROUP_DATA_CLEARED
} from "./shipdata.types";

export const updateTankData = (tank_data) => {
    return {
        type: TANK_DATA_UPDATED,
        payload: tank_data
    }
}

export const clearTankData = () => {
    return {
        type: TANK_DATA_CLEARED,
        payload: []
    }
}

export const updateMassData = (mass_data) => {
    return {
        type: MASS_DATA_UPDATED,
        payload: mass_data
    }
}

export const clearMassData = () => {
    return {
        type: MASS_DATA_CLEARED,
        payload: []
    }
}

export const updateVolumeData = (volume_data) => {
    return {
        type: VOLUME_DATA_UPDATED,
        payload: volume_data
    }
}

export const clearVolumeData = () => {
    return {
        type: VOLUME_DATA_CLEARED,
        payload: []
    }
}

export const updateGroupData = (group_data) => {
    return {
        type: GROUP_DATA_UPDATED,
        payload: group_data
    }
}

export const clearGroupData = () => {
    return {
        type: GROUP_DATA_CLEARED,
        payload: []
    }
}