import {START_DATE_UPDATED, END_DATE_UPDATED, SHIP_UPDATED} from "./vesselpicker.types";

export const updateStartDate = (start_date) => {
    return {
        type: START_DATE_UPDATED,
        payload: start_date
    }
}

export const updateEndDate = (end_date) => {
    return {
        type: END_DATE_UPDATED,
        payload: end_date
    }
}

export const updateCurrentShip = (current_ship) => {
    return {
        type: SHIP_UPDATED,
        payload: current_ship
    }
}