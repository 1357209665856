import React from "react";
import axios from 'axios';
import {
    AppBar,
    Typography,
    Toolbar,
	IconButton,
	Hidden,
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";

import {
	Check
} from "@material-ui/icons";

import MenuIcon from '@material-ui/icons/Menu';
import Config from "../config";
import { withStyles } from '@material-ui/core/styles'
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PropTypes from 'prop-types';
import ShipGroupMenu from "./ShipGroupMenu";
import {connect} from "react-redux";
import {
	toggleDrawer,
	openAccountMenu,
	closeAccountMenu,
	openSettingsMenu,
	closeSettingsMenu,
	updateContentComponent
} from "../redux/Dashboard/dashboard.actions";

import {
	showLoadingOverlay
} from "../redux/Login/login.actions";

import {
	changeSetting
} from "../redux/Settings/settings.actions";

import {clearUserProfile, updateUserProfile} from "../redux/Profile/profile.actions";
import {setSessionKey} from "../redux/Sessions/sessions.actions";


const drawerWidth = 240;
const useStyles = theme => ({
  root: {
    display: 'flex',
	height: '100%'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    margin: theme.spacing(3),
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column'
  },
  mainContent: {
	boxSizing: 'border-box',
	flexGrow: '1'
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -12
  },
});

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ship_groups: [],
		};

		this.handleSignOut = this.handleSignOut.bind(this);
	}
	
	componentDidMount() {
		axios.get(Config.API_URL + "profile.php", { headers: {"X-Session-Token": this.props.session_key} })
			.then(res => {
				if (res.status === 200 && res.data.status === "success") {
					const user_profile = {
							id: res.data.user.id,
							email: res.data.user.email,
							name: res.data.user.name,
							admin: (res.data.user.admin === 1)
					}
					this.props.updateUserProfile(user_profile);
				} else if (res.status === 200 && res.data.status === "unauth") {
					this.props.setSessionKey(null);
				} else {
					//TODO: Failure go back to login
				}})
			.catch(e => (
				console.log(e)
			))
			
		axios.get(Config.API_URL + "ship_list.php", { headers: {"X-Session-Token": this.props.session_key} })
			.then(res => {
				if (res.status === 200 && res.data.status === "success") {
					this.setState({ship_groups: res.data.groups});
				} else if (res.status === 200 && res.data.status === "unauth") {
					this.props.setSessionKey(null);
				} else {
					//TODO: Failure go back to login
				}})
			.catch(e => (
				console.log(e)
			))
	}
	
	handleSignOut() {
		axios.get(Config.API_URL + "sign_out.php", { headers: {"X-Session-Token": this.props.session_key} })
			.then(res => {
				if (res.status === 200 && res.data.status === "success") {
					this.props.setSessionKey(null);
					this.props.showLoadingOverlay(false);
				} else if (res.status === 200 && res.data.status === "unauth") {
					this.props.setSessionKey(null);
					this.props.showLoadingOverlay(false);
				} else {
					//TODO: Failure go back to login
				}})
			.catch(e => (
				console.log(e)
			))
	}
	
    render() {
		const { classes } = this.props;
		
        return (
		<div className={classes.root}>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<IconButton
					  color="inherit"
					  aria-label="open drawer"
					  edge="start"
					  onClick={() => this.props.toggleDrawer()}
					  className={classes.menuButton} >
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap>
						TankTrack
					</Typography>
					<section className={classes.rightToolbar}>

						<IconButton
						  color="inherit"
						  aria-label="settings"
						  edge="start"
						  onClick={() => this.props.openSettingsMenu()}
						  >
							<SettingsIcon />
						</IconButton>


						<IconButton
						  color="inherit"
						  aria-label="profile"
						  edge="start"
						  onClick={() => this.props.openAccountMenu()}
						>
							<AccountCircleIcon />
						</IconButton>
					</section>
				</Toolbar>
			</AppBar>
			
			<nav className={classes.drawer} aria-label="Ship navigation">
				<Hidden mdUp implementation="css">
					<Drawer
					  variant="temporary"
					  anchor="left"
					  open={this.props.mobile_open}
					  onClose={this.props.toggleDrawer}
					  classes={{
						paper: classes.drawerPaper,
					  }}
					  ModalProps={{
					    keepMounted: true, // Better performance on mobile.
					  }}
					>
					<div className={classes.toolbar} />
					<Divider />
					{this.state.ship_groups.map(function(group, index) {
						return <ShipGroupMenu key={index} GroupId={group["group_id"]} GroupName={group["group_name"]} Ships={group["ships"]} />
					})}

					</Drawer>
				</Hidden>

				<Hidden smDown implementation="css">
					<Drawer
					  classes={{
					    paper: classes.drawerPaper,
					  }}
					  variant="permanent"
					  open
					>
					<div className={classes.toolbar} />
					<Divider />
					{this.state.ship_groups.map(function(group, index) {
						return <ShipGroupMenu key={index} GroupId={group["group_id"]} GroupName={group["group_name"]} Ships={group["ships"]} />
					})}
					<Divider />
					</Drawer>
				</Hidden>
			</nav>
			<Drawer
				classes={{
					paper: classes.drawerPaper,
				}}
				variant="temporary"
				anchor="right"
				open={this.props.settings_menu_opened}
				onClose={() => this.props.closeSettingsMenu()}
				ModalProps={{
					keepMounted: true, // Better performance on mobile.
				}}
			>
				<div className={classes.toolbar} >
					<Toolbar>
						<Typography align="left">
							<b>Instellingen</b>
						</Typography>
					</Toolbar>
				</div>
				<Divider />
				<List>
					<ListItem button
					onClick={() => this.props.changeSetting('volume')}>
						{this.props.current_setting === 'volume'?
							<ListItemIcon>
								<Check />
							</ListItemIcon>:''
						}
						<ListItemText primary="Show Volume" />
					</ListItem>
					<ListItem button
				    onClick={() => this.props.changeSetting('mass')}>

						{this.props.current_setting === 'mass'?
							<ListItemIcon>
								<Check />
							</ListItemIcon>:''
						}
						<ListItemText primary="Show Mass" />
					</ListItem>
				</List>
			</Drawer>

			<Drawer
			  classes={{
				paper: classes.drawerPaper,
			  }}
			  variant="temporary"
			  anchor="right"
			  open={this.props.account_menu_opened}
			  onClose={() => this.props.closeAccountMenu()}
			  ModalProps={{
				keepMounted: true, // Better performance on mobile.
			  }}
			>
				<div className={classes.toolbar} >
					<Toolbar>
						<Typography align="left">
							{this.props.user_profile.name}
						</Typography>
					</Toolbar>
				</div>
				<Divider />
				<List>
					<ListItem button>
						<ListItemIcon>
							<AccountCircleIcon />
						</ListItemIcon>
						<ListItemText primary="Profile settings" />
					</ListItem>
					<ListItem button onClick={this.handleSignOut}>
						<ListItemIcon>
							<MeetingRoomIcon />
						</ListItemIcon>
						<ListItemText primary="Sign out" />
					</ListItem>
				</List>
			</Drawer>
			<div className={classes.content}>
				<div className={classes.toolbar} />
				<div className={classes.mainContent}>
					{this.props.content_component}
				</div>
			</div>
		</div>);
    };
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		session_key: state.session_state.session_key,
		mobile_open: state.dashboard_state.mobile_open,
		account_menu_opened: state.dashboard_state.account_menu_opened,
		settings_menu_opened: state.dashboard_state.settings_menu_opened,
		user_profile: state.profile_state.user_profile,
		content_component: state.dashboard_state.content_component,
		current_setting: state.settings_state.current_setting
	}
}

function mapDispatchToProps(dispatch) {
	return {
		toggleDrawer: () => dispatch(toggleDrawer()),
		setSessionKey: (session_key) => dispatch(setSessionKey(session_key)),
		openAccountMenu: () => dispatch(openAccountMenu()),
		closeAccountMenu: () => dispatch(closeAccountMenu()),

		openSettingsMenu: () => dispatch(openSettingsMenu()),
		closeSettingsMenu: () => dispatch(closeSettingsMenu()),

		changeSetting: (setting) => dispatch(changeSetting(setting)),

		clearUserProfile: () => dispatch(clearUserProfile()),
		updateUserProfile: (user_profile) => dispatch(updateUserProfile(user_profile)),
		updateContentComponent: (component) => dispatch(updateContentComponent(component)),
		showLoadingOverlay: (shown) => dispatch(showLoadingOverlay(shown))
	}
}

export default  connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles) (Dashboard));