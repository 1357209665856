import {USER_PROFILE_CLEARED, USER_PROFILE_UPDATED} from "./profile.types";

export const updateUserProfile = (user) => {
    return {
        type: USER_PROFILE_UPDATED,
        payload: user
    }
}

export const clearUserProfile = () => {
    return {
        type: USER_PROFILE_CLEARED
    }
}