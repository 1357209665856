import {USER_PROFILE_UPDATED, USER_PROFILE_CLEARED} from "./profile.types";

const INITIAL_STATE = {
    user_profile: {
        id: -1,
        email: "",
        name: "",
        admin: false
    }
}

const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case USER_PROFILE_CLEARED:
            return {
                ...state,
                user_profile: INITIAL_STATE
            }
        case USER_PROFILE_UPDATED:
            console.log(action.payload)
            console.log("Updated")
            return {
                ...state,
                user_profile: action.payload
            }
        default:
            return state
    }
}

export default reducer;