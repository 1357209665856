import React from "react";
import axios from 'axios';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    Typography,
	Hidden,
	Grid,
	Button,
} from "@material-ui/core";

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import Config from "../config";

import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles'
import ShipTotalsGraph from "./ShipTotalsGraph";
import ShipDetails from "./ShipDetails";

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import {updateContentComponent} from "../redux/Dashboard/dashboard.actions";
import {updateCurrentShip, updateEndDate, updateStartDate} from "../redux/VesselPicker/vesselpicker.actions";

const moment = require('moment');

const drawerWidth = 240;
const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  middleContainer: {
	 height: `calc(100% - ${theme.spacing(3)}px)`,
	 display: 'flex',
	 alignItems: 'center',
	 justifyContent: 'center',
  },
  vesselMessage: {
	  color: '#707070',
	  marginTop: '10px'
  },
  optionsItem: {
	  marginLeft: '10px',
	  marginTop: '10px',
	  width: '250px'
  }
});

class ShipTotals extends React.Component {
	constructor(props) {
		super(props);
		let startdt = props.start_date ? props.start_date : new Date(new Date().setDate(new Date().getDate() - 90))
		props.updateStartDate(startdt);
		let enddt = props.end_date ? props.end_date : new Date();
		props.updateEndDate(enddt);
		this.handleOpenDetails = this.handleOpenDetails.bind(this);
		this.handleExcelExportClick = this.handleExcelExportClick.bind(this);
	}
	
	handleOpenDetails() {
		let component = <ShipDetails/>
		this.props.updateContentComponent(component)
	}
	
	handleExcelExportClick() {
		const ship_url = Config.API_URL + "ship_data_total.php?id=" + this.props.current_ship.ship_id + "&start=" + moment(this.props.start_date).format("YYYY-MM-DD") + "&end=" + moment(this.props.end_date).format("YYYY-MM-DD");
		
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileExtension = '.xlsx';
		const offset = new Date().getTimezoneOffset();
		
        axios.get(ship_url, { headers: {"X-Session-Token": this.props.session_key} }).then((resp) => {
			let export_data = [];
			let tank_data = resp.data.data;
			
			for (const [, entry] of tank_data.entries()) {
				let entry_date = moment(entry.datetime).add(-offset, 'minutes');
				entry.datetime = entry_date.format();
				
				export_data.push(entry);
			}
			
			const ws = XLSX.utils.json_to_sheet(export_data);
			const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
			const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
			const data = new Blob([excelBuffer], {type: fileType});
			FileSaver.saveAs(data, this.props.current_ship.ship_name + fileExtension);
        }).catch((error) => {
			alert("Failure during Excel export");
            console.log(error);
        })
	}
	
	renderFilterOptions() {
		const { classes } = this.props;
		
		return (
			<React.Fragment>
				<KeyboardDatePicker
					className={classes.optionsItem}
					margin="normal"
					id="date-picker-dialog"
					label="Start date"
					format="yyyy-MM-dd"
					value={this.props.start_date}
					onChange={(date) => this.props.updateStartDate(date)}
					KeyboardButtonProps={{
						'aria-label': 'change starting date',
					}}
				/>
				<KeyboardDatePicker
					className={classes.optionsItem}
					margin="normal"
					id="date-picker-dialog"
					label="End date"
					format="yyyy-MM-dd"
					value={this.props.end_date}
					onChange={(date) => this.props.updateEndDate(date)}
					KeyboardButtonProps={{
						'aria-label': 'change end date',
					}}
				/>
			</React.Fragment>
		)
	}
	
	renderOptions() {
		const { classes } = this.props;
		
		return (
			<React.Fragment>
				<Button variant="contained" color="primary" className={classes.optionsItem} onClick={this.handleExcelExportClick} style={{backgroundColor: "#008000"}}>Excel Export</Button>
				<Button variant="contained" color="primary" className={classes.optionsItem} onClick={this.handleOpenDetails}>Per Tank Details</Button>
			</React.Fragment>
		)
	}
	
    render() {
		const { classes } = this.props;
		
        return (
			<div>
				<Typography variant='h5' className={classes.vesselMessage}>
					{this.props.current_ship.ship_name}
				</Typography>
				<Typography variant='h6' className={classes.vesselMessage}>
					Options
				</Typography>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Hidden smDown implementation="css">
						<Grid container direction={'row'}>
							{this.renderFilterOptions()}
						</Grid>
						<Grid container direction={'row'}>
							{this.renderOptions()}
						</Grid>
					</Hidden>
					<Hidden mdUp implementation="css">
						<Grid container direction="column">
							{this.renderFilterOptions()}
						</Grid>
						<Grid container direction="column">
							{this.renderOptions()}
						</Grid>
					</Hidden>
				</MuiPickersUtilsProvider>
				<Typography variant='h6' className={classes.vesselMessage}>
					Data
				</Typography>
				<ShipTotalsGraph />
			</div>
		);
    };
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.current_ship.ship_id !== this.props.current_ship.ship_id) {
			this.props.updateCurrentShip(this.props.current_ship);
		}
	}

}

ShipTotals.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		session_key: state.session_state.session_key,
		current_ship: state.vesselpicker_state.current_ship,
		start_date: state.vesselpicker_state.start_date,
		end_date: state.vesselpicker_state.end_date
	}
}

function mapDispatchToProps(dispatch) {
	return {
		updateContentComponent: (component) => dispatch(updateContentComponent(component)),
		updateCurrentShip: (current_ship) => dispatch(updateCurrentShip(current_ship)),
		updateStartDate: (start_date) => dispatch(updateStartDate(start_date)),
		updateEndDate: (end_date) => dispatch(updateEndDate(end_date))
	}
}

export default  connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles) (ShipTotals));