export const TANK_DATA_UPDATED = "TANK_DATA_UPDATED";
export const TANK_DATA_CLEARED = "TANK_DATA_CLEARED";

export const VOLUME_DATA_UPDATED = "VOLUME_DATA_UPDATED";
export const VOLUME_DATA_CLEARED = "VOLUME_DATA_CLEARED";

export const MASS_DATA_UPDATED = "MASS_DATA_UPDATED";
export const MASS_DATA_CLEARED = "MASS_DATA_CLEARED";

export const GROUP_DATA_UPDATED = "GROUP_DATA_UPDATED";
export const GROUP_DATA_CLEARED = "GROUP_DATA_CLEARED";