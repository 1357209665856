import {SESSIONKEY_CLEARED, SESSIONKEY_SET} from "./sessions.types";

export const setSessionKey = (session_key) => {
    return {
        type: SESSIONKEY_SET,
        payload: session_key
    }
}

export const clearSessionKey = () => {
    return {
        type: SESSIONKEY_CLEARED
    }
}