import {VOLUME_DATA_UPDATED, VOLUME_DATA_CLEARED, TANK_DATA_UPDATED, TANK_DATA_CLEARED, MASS_DATA_UPDATED, MASS_DATA_CLEARED, GROUP_DATA_UPDATED, GROUP_DATA_CLEARED} from "./shipdata.types";


const INITIAL_STATE = {
    tank_data: [],
    volume_data: [],
    mass_data: [],
	group_data: []
}

const reducer = (state= INITIAL_STATE, action) => {
    switch (action.type) {
        case VOLUME_DATA_UPDATED:
            return {
                ...state,
                volume_data: action.payload
            }
        case VOLUME_DATA_CLEARED:
            return {
                ...state,
                volume_data: action.payload
            }
        case TANK_DATA_CLEARED:
            return {
                ...state,
                tank_data: action.payload
            }
        case TANK_DATA_UPDATED:
            return {
                ...state,
                tank_data: action.payload
            }
        case MASS_DATA_UPDATED:
            return {
                ...state,
                mass_data: action.payload
            }
        case MASS_DATA_CLEARED:
            return {
                ...state,
                mass_data: action.payload
            }
        case GROUP_DATA_UPDATED:
            return {
                ...state,
                group_data: action.payload
            }
        case GROUP_DATA_CLEARED:
            return {
                ...state,
                group_data: action.payload
            }
        default:
            return state
    }
}

export default reducer;