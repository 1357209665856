import {TOGGLE_DRAWER, ACCOUNT_MENU_CHANGED, CONTENT_COMPONENT_CHANGED, SETTINGS_MENU_CHANGED} from "./dashboard.types";

export const toggleDrawer = () => {
    return {
        type: TOGGLE_DRAWER,
    }
}

export const openSettingsMenu = () => {
    return {
        type: SETTINGS_MENU_CHANGED,
        payload: true
    }
}

export const closeSettingsMenu = () => {
    return {
        type: SETTINGS_MENU_CHANGED,
        payload: false
    }
}

export const openAccountMenu = () => {
    return {
        type: ACCOUNT_MENU_CHANGED,
        payload: true
    }
}

export const closeAccountMenu = () => {
    return {
        type: ACCOUNT_MENU_CHANGED,
        payload: false
    }
}

export const updateContentComponent = (component) => {
    return {
        type: CONTENT_COMPONENT_CHANGED,
        payload: component
    }
}