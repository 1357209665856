import React from "react";
import axios from 'axios';
import Cookies from 'universal-cookie';
import {Redirect} from 'react-router-dom';

import {connect} from 'react-redux';

import {
    Button,
    TextField,
    Grid,
    Paper,
    AppBar,
    Typography,
    Toolbar,
    Link,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Backdrop,
	CircularProgress,
} from "@material-ui/core";

import Config from "../config";
import {setSessionKey} from "../redux/Sessions/sessions.actions";
import {setLoginName, setPassword, openFailDialog, showLoadingOverlay} from "../redux/Login/login.actions";

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }
	
    handleSubmit(event) {
        event.preventDefault();
        this.props.showLoadingOverlay(true);
		axios.post(Config.API_URL + "auth.php", { email: this.props.email, password: this.props.password })
		.then(res => {
			if (res.status === 200 && res.data.status === "success") {
				const cookies = new Cookies();
				cookies.set('session', res.data.session, { path: '/' });
				this.props.setSessionKey(res.data.session);
			} else {
				this.props.showLoadingOverlay(false);
				this.props.openFailDialog(true);
			}
		}).catch(e => (
			console.log(e)
		))
		
    }
	
    render() {
		if (this.props.session_key) {
			return <Redirect to="/"/>
		} else {
			return (
				<div>
					<AppBar position="static" alignitems="center" color="primary">
						<Toolbar>
							<Grid container justify="center" wrap="wrap">
								<Grid item>
									<Typography variant="h6">TankTrack</Typography>
								</Grid>
							</Grid>
						</Toolbar>
					</AppBar>
					<Grid container spacing={0} justify="center" direction="row">
						<Grid item>
							<Grid container direction="column" justify="center" spacing={2} className="login-form">
								<Paper variant="elevation" elevation={2} className="login-background">
									<Grid item>
										<Typography component="h1" variant="h5" id="login-title">Sign in</Typography>
									</Grid>
									<Grid item>
										<form onSubmit={this.handleSubmit}>
											<Grid container direction="column" spacing={2}>
												<Grid item>
													<TextField type="email" placeholder="Email" fullWidth name="email" variant="outlined" value={this.props.email} onChange={(event) => this.props.setLoginName(event.target.value)} required autoFocus/>
												</Grid>
												<Grid item>
													<TextField type="password" placeholder="Password" fullWidth name="password" variant="outlined" value={this.props.password} onChange={(event) => this.props.setPassword(event.target.value)} required/>
												</Grid>
												<Grid item>
													<Button variant="contained" color="primary" type="submit" className="button-block">Submit</Button>
												</Grid>
											</Grid>
										</form>
									</Grid>
									<Grid item align="center" id="reset-password">
										<Link href="#" variant="body2">Forgot Password?</Link>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
					<Dialog
					  open={this.props.login_fail_dialog_open}
					  onClose={() => this.props.openFailDialog(false)}
					  aria-labelledby="login-fail-dialog-title"
					  aria-describedby="login-failed">
						<DialogTitle id="login-fail-dialog-title">{"Login failure!"}</DialogTitle>
						<DialogContent>
							<DialogContentText id="login-fail-dialog-description">
								Invalid email and/or password.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.props.openFailDialog(false)} color="primary" autoFocus>
								OK
							</Button>
						</DialogActions>
					</Dialog>
					<Backdrop id="loading-overlay" open={this.props.loading_overlay_open}>
						<CircularProgress color="inherit" />
					</Backdrop>
				</div>);
			}
    	}
}

function mapStateToProps(state) {
	return {
		email: state.login_state.email,
		password: state.login_state.password,

		login_fail_dialog_open: state.login_state.login_fail_dialog_open,
		loading_overlay_open: state.login_state.loading_overlay_open,
		session_key: state.session_state.session_key
	}
}

function mapDispatchToProps(dispatch) {
	return {
		setSessionKey: (session_key) => dispatch(setSessionKey(session_key)),
		setLoginName: (login_name) => dispatch(setLoginName(login_name)),
		setPassword: (password) => dispatch(setPassword(password)),
		openFailDialog: (opened) => dispatch(openFailDialog(opened)),
		showLoadingOverlay: (shown) => dispatch(showLoadingOverlay(shown))
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);