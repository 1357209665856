import React from "react";
import {
	List,
	ListItem,
	ListItemText,
	Collapse,
	Menu,
	MenuItem
} from "@material-ui/core";

import ListItemIcon from '@material-ui/core/ListItemIcon';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import PropTypes from 'prop-types';
import ShipTotals from "../ShipTotals"
import {connect} from "react-redux";
import {updateContentComponent} from "../../redux/Dashboard/dashboard.actions";
import {updateCurrentShip} from "../../redux/VesselPicker/vesselpicker.actions";

const drawerWidth = 240;
const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

class ShipGroupMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			mouseX: null,
			mouseY: null
		};
		
		this.group_id = props.GroupId;
		this.group_name = props.GroupName;
		this.ships = props.Ships;
		
		this.handleExpand = this.handleExpand.bind(this);
		this.handleShipSelect = this.handleShipSelect.bind(this);
		
		this.handleContextMenuOpen = this.handleContextMenuOpen.bind(this);
		this.handleContextMenuClose = this.handleContextMenuClose.bind(this);
	}
	
	handleExpand() {
		this.setState({open: !this.state.open});
	}
	
	handleShipSelect(ship) {
		this.props.updateCurrentShip(ship);
		let component = <ShipTotals/>;
		this.props.updateContentComponent(component);
	}
	
	handleContextMenuOpen(event) {
		event.preventDefault();
		this.setState({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		});
	}
	
	handleContextMenuClose() {
		this.setState({
			mouseX: null,
			mouseY: null,
		});
	}
	
    render() {
		const ContextMenu = (
			<Menu
			  keepMounted
			  open={this.state.mouseY !== null}
			  onClose={this.handleContextMenuClose}
			  anchorReference="anchorPosition"
			  anchorPosition={
				this.state.mouseY !== null && this.state.mouseX !== null
				  ? { top: this.state.mouseY, left: this.state.mouseX }
				  : undefined
				}
			>
				<MenuItem>
					<ListItemIcon>
						<EditIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Edit" />
				</MenuItem>
				<MenuItem>
					<ListItemIcon>
						<DeleteIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Remove" />
				</MenuItem>
			</Menu>
		)
				
        return (
			<List>
				{ContextMenu}
				<ListItem button onClick={this.handleExpand} onContextMenu={this.handleContextMenuOpen}>
					<ListItemIcon>
						<GroupWorkIcon />
					</ListItemIcon>
					<ListItemText primary={this.group_name} />
					{this.state.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={this.state.open} timeout="auto" unmountOnExit>
					{this.ships.map((ship, key) => (
						<ListItem key={key} button onClick={() => this.handleShipSelect(ship)}>
							<ListItemIcon>
								<DirectionsBoatIcon />
							</ListItemIcon>
							<ListItemText secondary={ship.ship_name} />
						</ListItem>
					))}
				</Collapse>
			</List>
		);
    };
}

ShipGroupMenu.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return {
		updateContentComponent: (component) => dispatch(updateContentComponent(component)),
		updateCurrentShip: (ship) => dispatch(updateCurrentShip(ship))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(ShipGroupMenu));
