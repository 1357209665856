import React from "react";
import axios from 'axios';
import {
    Typography,
	Card,
	CardContent
} from "@material-ui/core";

import Config from "../config";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { withStyles } from '@material-ui/core/styles'
import LineGraph from "./LineGraph";
import {updateTankData, clearTankData} from "../redux/ShipData/shipdata.actions";

const moment = require('moment');

const drawerWidth = 240;
const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  middleContainer: {
	 height: `calc(100% - ${theme.spacing(3)}px)`,
	 display: 'flex',
	 alignItems: 'center',
	 justifyContent: 'center',
  },
  vesselMessage: {
	  color: '#707070'
  },
  lineCard: {
	  marginTop: "10px"
  }
});

class ShipDetailsGraphs extends React.Component {
	constructor(props) {
		super(props);

		this.RenderGraph = this.RenderGraph.bind(this);
	}
	
	RenderGraph(key) {
		const { classes } = this.props;
		
		if (this.props.current_setting === "mass") {
			return (
				<React.Fragment>
					<Typography variant='h6' className={classes.vesselMessage}>
						{key} - Actual Mass {this.getLastMassString(key)}
					</Typography>
					<LineGraph label={"Actual Mass - " + key} yLabel="Mass (KG)" backgroundColor="rgba(255, 121, 77, 0.5)" borderColor="rgb(255, 121, 77)" data={this.props.tank_data[key]["mass"]} />
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					<Typography variant='h6' className={classes.vesselMessage}>
						{key} - Actual Volume {this.getLastVolumeString(key)}
					</Typography>
					<LineGraph label={"Actual Volume - " + key} yLabel="Volume (Liter)" backgroundColor="rgba(77, 121, 255, 0.5)" borderColor="rgb(77, 121, 255)" data={this.props.tank_data[key]["volume"]} />
				</React.Fragment>
			);
		}
	}
	
    render() {
		const { classes } = this.props;
		
        return (
			Object.keys(this.props.tank_data).map((key, index) => (
				<Card key={"tankdetail-" + key} className={classes.lineCard}>
					<CardContent>
						
						{this.RenderGraph(key)}
					</CardContent>
				</Card>
			))
		);
    };
	
	getLastMassString(tank) {
		let lastEntry = this.props.tank_data[tank]["mass"].slice(-1)[0];
		
		if (lastEntry) {
			return "(" + lastEntry.y.toLocaleString('nl') + " kg @ " + moment(lastEntry.x).fromNow() + ")";
		} else {
			return "";
		}
	}
	
	getLastVolumeString(tank) {
		let lastEntry = this.props.tank_data[tank]["volume"].slice(-1)[0];
		
		if (lastEntry) {
			return "(" + lastEntry.y.toLocaleString('nl') + " liter @ " + moment(lastEntry.x).fromNow() + ")";
		} else {
			return "";
		}
	}
	
    updateShipData(ship_id, start, end){
        const ship_url = Config.API_URL + "ship_data_details.php?id=" + ship_id + "&start=" + moment(start).format("YYYY-MM-DD") + "&end=" + moment(end).format("YYYY-MM-DD");
		
        axios.get(ship_url, { headers: {"X-Session-Token": this.props.session_key} }).then((resp) => {
            this.prepareGraphData(resp.data);
        }).catch((error) => {
			this.props.clearTankData();
            console.log(error);
        })
    }
	
	prepareGraphData(data) {
		if (!data) {
			this.props.clearTankData();
			return;
		}
		
		if (data.status !== "success") {
			this.props.clearTankData();
			return;
		}
		
		var offset = new Date().getTimezoneOffset();
		
		let tanks_graph_data = {};
		
		let start = new Date();
		
		let tanks_data = data.tanks;
	
		for (const [, tank] of tanks_data.entries()) {
			let tank_volumes = []
			let tank_mass = []
			for (const [, entry] of tank.data.entries()) {
				//let entry_date = moment(entry.datetime).add(-offset, 'minutes'); // MomentJS is slow :-(
				let entry_date = new Date(entry.datetime).getTime() - (offset * 60000);
				let volume = Math.round(entry.volume);
				let mass = Math.round(volume * entry.density);
				
				tank_volumes.push({
					x: entry_date,
					y: volume
				});
				if (entry.density != null) {				
					tank_mass.push({
						x: entry_date,
						y: mass
					})
				}
			}

			tanks_graph_data[tank.tank] = {}
			tanks_graph_data[tank.tank]["volume"] = tank_volumes;
			tanks_graph_data[tank.tank]["mass"] = tank_mass;
		}
		
		let end = new Date();
		let time = end.getTime() - start.getTime();
		console.log('Finished in', time, 'ms');

		this.props.updateTankData(tanks_graph_data)
	}
	
	componentDidUpdate(prevProps) {
    	let changed = false;

		if (prevProps.current_ship.ship_id !== this.props.current_ship.ship_id) {
			changed = true;
		}

		if (prevProps.start_date !== this.props.start_date) {
			changed = true;
		}

		if (prevProps.end_date !== this.props.end_date) {
			changed = true;
		}

		if (changed) {
			this.props.clearTankData();
			this.updateShipData(this.props.current_ship.ship_id, this.props.start_date, this.props.end_date);
		}
	}
	
	componentDidMount() {
    	this.props.clearTankData();
		this.updateShipData(this.props.current_ship.ship_id, this.props.start_date, this.props.end_date);
	}
}

ShipDetailsGraphs.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	return {
		session_key: state.session_state.session_key,
		current_ship: state.vesselpicker_state.current_ship,
		start_date: state.vesselpicker_state.start_date,
		end_date: state.vesselpicker_state.end_date,
		tank_data: state.shipdata_state.tank_data,
		current_setting: state.settings_state.current_setting
	}
}

function mapDispatchToProps(dispatch) {
	return {
		updateTankData: (tank_data) => dispatch(updateTankData(tank_data)),
		clearTankData: () => dispatch(clearTankData())
	}
}

export default  connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles) (ShipDetailsGraphs));