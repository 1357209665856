import {combineReducers} from "redux";

import SessionReducer from './Sessions/sessions.reducers'
import LoginReducer from './Login/login.reducers'
import DashboardReducer from './Dashboard/dashboard.reducers'
import ProfileReducer from './Profile/profile.reducers'
import VesselPickerReducer from './VesselPicker/vesselpicker.reducers'
import ShipDataReducer from './ShipData/shipdata.reducers'
import SettingsReducer from './Settings/settings.reducers'

export default combineReducers({
    session_state: SessionReducer,
    login_state: LoginReducer,
    dashboard_state: DashboardReducer,
    profile_state: ProfileReducer,
    vesselpicker_state: VesselPickerReducer,
    shipdata_state: ShipDataReducer,
    settings_state: SettingsReducer,
})