import {LOGINNAME_CHANGED, PASSWORD_CHANGED, LOADINGOVERLAY_SHOWN, LOGINFAILDIALOG_SHOWN} from "./login.types";


const INITIAL_STATE = {
    email: "",
    password: "",
    login_fail_dialog_open: false,
    loading_overlay_open: false
}

const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case LOGINNAME_CHANGED:
            return {
                ...state,
                email: action.payload
            }
        case PASSWORD_CHANGED:
            return {
                ...state,
                password: action.payload
            }

        case LOADINGOVERLAY_SHOWN:
            return {
                ...state,
                loading_overlay_open: action.payload
            }

        case LOGINFAILDIALOG_SHOWN:
            return {
                ...state,
                login_fail_dialog_open: action.payload
            }

        default:
            return state
    }
}

export default reducer;