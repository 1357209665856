import {CURRENT_SETTING_CHANGED} from "./settings.types";


const INITIAL_STATE = {
    current_setting: 'volume',
}

const reducer = (state= INITIAL_STATE, action) => {
    switch (action.type) {
        case CURRENT_SETTING_CHANGED:
            return {
                ...state,
                current_setting: action.payload
            }
        default:
            return state
    }
}

export default reducer;