import {ACCOUNT_MENU_CHANGED, TOGGLE_DRAWER, CONTENT_COMPONENT_CHANGED, SETTINGS_MENU_CHANGED} from "./dashboard.types";
import ChooseShip from "../../Dashboard/ChooseShip";
import React from "react";


const INITIAL_STATE = {
    mobile_open: false,
    account_menu_opened: false,
    settings_menu_opened: false,
    ship_groups: [],
    content_component: <ChooseShip/>
}

const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case TOGGLE_DRAWER:
            return {
                ...state,
                mobile_open: !state.mobile_open
            }

        case ACCOUNT_MENU_CHANGED:
            return {
                ...state,
                account_menu_opened: action.payload
            }
        case SETTINGS_MENU_CHANGED:
            return {
                ...state,
                settings_menu_opened: action.payload
            }

        case CONTENT_COMPONENT_CHANGED:
            console.log(action.payload);
            return {
                ...state,
                content_component: action.payload,
            }
        default:
            return state
    }
}

export default reducer;