import {SHIP_UPDATED, END_DATE_UPDATED, START_DATE_UPDATED} from "./vesselpicker.types";


const INITIAL_STATE = {
    current_ship: null,
    start_date: null,
    end_date: null
}

const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SHIP_UPDATED:
            return {
                ...state,
                current_ship: action.payload
            }
        case START_DATE_UPDATED:
            return {
                ...state,
                start_date: action.payload
            }

        case END_DATE_UPDATED:
            return {
                ...state,
                end_date: action.payload
            }
        default:
            return state
    }
}

export default reducer;